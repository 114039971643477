import * as React from "react"
import HomePage from "../components/home/HomePage"
// styles


// data

// markup
const IndexPage = () => {
  return (
    <main >
      <HomePage/>
    </main>
  )
}

export default IndexPage
